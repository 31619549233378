import { Link } from "gatsby"
import React, { useState, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import MobileNavigation from "./mobile-navigation"
import Navigation from "./navigation"
import { StoreContext } from "../../context/store-context"

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { cart, fullPrice } = useContext(StoreContext)
  const shopSubMenuRaw = useStaticQuery(graphql`
    query {
      allSanityShopSiteStructure {
        nodes {
          field_title
          field_order
          field_menu_items {
            item_collection_reference {
              slug {
                current
              }
            }
            item_collection_title
          }
          slug {
            current
          }
        }
      }
    }
  `)

  const data = [...shopSubMenuRaw.allSanityShopSiteStructure.nodes]
  data.sort((x, y) => x.field_order - y.field_order)

  const shopSubMenu = [
    { title: "Get Inspired", href: "/shop/" },
    ...data.map(x => ({
      title: x.field_title,
      href: x.slug.current,
      children: x.field_menu_items.map(x => ({
        title: x.item_collection_title,
        href: `/shop/${x.item_collection_reference.slug.current}/`,
      })),
    })),
  ]

  const menuData = [
    {
      title: "Home",
      href: "/",
    },
    {
      title: "Explorer",
      href: "/explorer",
    },
    {
      title: "Recipes",
      href: "/recipe",
    },
    {
      title: "About",
      href: "/about",
    },
    {
      title: "Shop",
      children: shopSubMenu,
      href: "/shop/",
    },
  ]

  return (
    <header
      className="header__container"
      style={
        process.env.GATSBY_ACTIVE_ENV === "development" ||
        process.env.NODE_ENV === "development"
          ? { background: "green" }
          : {}
      }
    >
      <div className="container">
        <div className="header">
          <div className="is-hidden-tablet">
            <i
              onClick={() => setMenuOpen(true)}
              className="icon menu icon-burger"
            />
          </div>
          <Link to="/">
            <img src={"/vera.svg"} className="logo" />
          </Link>
          <MobileNavigation
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            menuData={menuData}
          />
          <Navigation menuData={menuData} shopSubMenuItems={data} />
          <Link
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "0.875rem",
            }}
            to="/shop/cart"
          >
            {cart.length > 0 && (
              <span style={{ marginRight: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>{fullPrice()} DKK</span> (
                {`${cart.length}`})
              </span>
            )}

            <i className="icon icon-cart cart" />
          </Link>
        </div>
      </div>
    </header>
  )
}

export default Header
