export const linkResolver = ({ _type, slug, productSlug }) => {
  switch (_type) {
    case "collection":
      return `/shop/${slug}`
    case "article":
      return `/article/${slug}`
    case "recipe":
      return `/recipe/${slug}`
    case "dynamic_page":
      return `/${slug}`
    case "article_type":
      return slug === "recipe" ? `/recipe/` : `/category/${slug}`
    case "product":
      return `/shop/${productSlug}/${slug}`
  }
}

export const borderColorResolver = cmsColor => {
  switch (cmsColor) {
    case "orange":
      return "has-border-orange"
    case "green":
      return "has-border-success"
    case "pink":
      return "has-border-pink"
    case "purple":
      return "has-border-purple"
    case "red":
      return "has-border-danger"
    case "blue":
      return "has-border-dark"
    case "grey":
      return "has-border-light"
    case "yellow":
      return "has-border-yellow"
    case "none":
      return ""
  }
}

export const bgColorResolver = cmsColor => {
  switch (cmsColor) {
    case "orange":
      return "has-background-orange"
    case "green":
      return "has-background-success"
    case "pink":
      return "has-background-pink"
    case "purple":
      return "has-background-purple"
    case "red":
      return "has-background-danger"
    case "blue":
      return "has-background-dark"
    case "lightblue":
      return "has-background-blue"
    case "grey":
      return "has-background-light"
    case "yellow":
      return "has-background-yellow"
    case "none":
      return ""
  }
}

export const colorResolver = cmsColor => {
  switch (cmsColor) {
    case "green":
      return "has-text-white"
    case "pink":
      return "has-text-white"
    case "purple":
      return "has-text-white"
    case "red":
      return "has-text-white"
    case "blue":
      return "has-text-white"
    case "lightblue":
      return "has-text-white"
    default:
      return ""
  }
}

export const asSingleWithAmount = cart => {
  const aux = []
  for (let i = 0; i < cart.length; i++) {
    if (aux.length === 0) {
      aux.push({
        variantKey: cart[i].variant._key,
        amount: 1,
        ...cart[i],
      })
    } else {
      const item = aux.find(x => x.variantKey === cart[i].variant._key)
      if (item) {
        item.amount += 1
      } else {
        aux.push({
          variantKey: cart[i].variant._key,
          amount: 1,
          ...cart[i],
        })
      }
    }
  }

  return aux
}

export const asSingle = cart => {
  const aux = []
  for (let i = 0; i < cart.length; i++) {
    const item = aux.find(x => x.variant._key === cart[i].variant._key)
    if (!item || aux.length === 0) {
      aux.push({
        ...cart[i],
      })
    }
  }

  return aux
}

export const debounce = (func, wait, immediate) => {
  var timeout
  return function() {
    var context = this,
      args = arguments
    var later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export const getDeliveryObject = name =>
  deliveryMethods.find(x => x.name === name)

export const deliveryMethods = [
  {
    title: "Pakkeshop Danmark",
    name: "pakkeshop",
    price: 50,
  },
  {
    name: "pickup",
    title: 'Free pickup in "Kødbyen, Cophenhagen"',
    price: 0,
  },
  {
    name: "international",
    title: "International / All orders outside of Denmark",
    price: 100,
  },
]

export const paginate = (list, page) => list.slice(0, page * 9)
